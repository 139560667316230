<template>
  <div class="p-root">
    <div
      class="div-background full-width column-center"
      :style="{ backgroundImage: `url(${icons.intro_tu1})` }"
    >
      <span class="intro-title-english" style="margin-top: 50px"
        >Inscription</span
      >
      <span class="intro-title-blue"> 我要报名 </span>
      <img :src="icons.intro_triangle2" height="15" style="margin-top: 10px" />

      <div class="intro-content-width column">
        <span style="padding-bottom: 10px">账号</span>
        <div
          class="row"
          style="padding-bottom: 10px; border-bottom: 1px solid #dedede"
        >
          <a-icon type="user" style="color: #dedede; margin-right: 12px" />
          <input
            v-model="loginForm.phone"
            type="text"
            placeholder="请输入手机号"
            class="empty-input"
            @keyup.enter="login"
          />
        </div>
        <span style="margin-top: 40px; padding-bottom: 10px">动态密码</span>
        <div
          class="row"
          style="padding-bottom: 10px; border-bottom: 1px solid #dedede"
        >
          <a-icon type="lock" style="color: #dedede; margin-right: 12px" />
          <input
            v-model="loginForm.sms_code"
            type="text"
            placeholder="请输入动态密码"
            class="empty-input"
            @keyup.enter="login"
          />
          <div style="flex: 1"></div>
          <a-button
            :disabled="getSmsBtnState.disabled"
            :loading="getSmsBtnState.loading"
            type="primary"
            style="
              color: #fff;
              margin-right: 0px;
              background: #295a9f;
              border-color: #295a9f;
            "
            @click="sendCode"
            >{{ duration > 0 ? duration + "秒" : "获取" }}</a-button
          >
        </div>
        <a
          @click="goRegister"
          style="margin-top: 20px; align-self: flex-end; color: white"
          >首次报名请点击立即注册</a
        >
        <a-button
          :loading="loginBtnState.loading"
          @click="login"
          type="primary"
          style="
            height: 40px;
            margin-top: 90px;
            margin-bottom: 20px;
            border-radius: 20px;
            background: #295a9f;
            border-color: #295a9f;
          "
        >
          登录
        </a-button>
        <div class="join-tip" style="align-self: center">
          已有账号可立即登录
        </div>
        <div style="height: 50px"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import * as regHelper from "../../utils/regHelper";
import * as eventHelper from "../../utils/eventHelper";
export default {
  data() {
    return {
      icons: this.$root.icons,

      // 按钮状态
      getSmsBtnState: {
        disabled: false,
        loading: false,
      },
      loginBtnState: {
        loading: false,
      },

      duration: 0,

      // 表单
      loginForm: {
        phone: "",
        sms_code: "",
      },
    };
  },
  mounted() {
    setTimeout(() => {
      eventHelper.triggerEvent("footer-change-background", {
        backgroundColor: "#062C3E",
        fontColor: "#ffffff",
      });
    }, 500);

    if (localStorage.getItem("token")) {
      this.$router.push({
        name: "enroll",
      });
    }
  },
  methods: {
    ...mapActions({
      sendSMSAction: "sendSMS",
      userLoginAction: "login",
    }),
    checkPhone() {
      if (this.loginForm.phone == "") {
        this.$message.error("请输入手机号");
        return false;
      }
      if (!regHelper.isPhone(this.loginForm.phone)) {
        this.$message.error("手机号格式不正确");
        return false;
      }
      return true;
    },
    sendCode() {
      if (this.getSmsBtnState.loading) {
        return;
      }
      if (!this.checkPhone()) {
        return;
      }

      this.getSmsBtnState.disabled = true;
      this.getSmsBtnState.loading = true;

      this.sendSMSAction({
        phone: this.loginForm.phone,
      })
        .then((res) => {
          this.duration = 60;
          this.timer = setInterval(() => {
            this.duration--;
            if (this.duration == 0) {
              clearInterval(this.timer);
              this.getSmsBtnState.disabled = false;
              this.getSmsBtnState.loading = false;
            }
          }, 1000);
        })
        .catch((error) => {
          this.$message.error(error);
        });
    },
    goRegister() {
      this.$router.push({
        name: "register",
      });
    },
    login() {
      if (!this.checkPhone()) {
        return;
      }
      if (this.loginForm.sms_code == "") {
        this.$message.error("请输入动态密码");
        return;
      }

      this.loginBtnState.loading = true;
      this.loginBtnState.disabled = true;

      this.userLoginAction(this.loginForm)
        .then((res) => {
          this.goNext();
        })
        .catch((error) => {
          this.$message.error(error);
        })
        .finally(() => {
          this.loginBtnState.loading = false;
        });
    },
    goNext() {
      this.$router.push({
        name: "join-guide",
      });
    },
  },
};
</script>

<style scoped>
.intro-content-width {
  width: 600px;
}
.intro-title-blue {
  font-family: PingFangSC-Semibold;
  font-size: 36px;
  color: #282828;
  letter-spacing: 0;
  text-align: center;
}
.intro-content-white-24 {
  font-family: PingFangSC-Semibold;
  font-size: 24px;
  color: #ffffff;
  letter-spacing: 0;
  text-align: center;
}
.intro-title-english {
  font-family: PingFangSC-Semibold;
  font-size: 24px;
  color: #282828;
  letter-spacing: 0;
  text-align: center;
}
.intro-title-yellow {
  font-family: ZhenyanGB-Regular;
  font-size: 30px;
  color: #f7eb50;
  letter-spacing: 0;
}
.intro-btn {
  cursor: pointer;
  background: #062c3e;
  border-radius: 8px;
  padding: 0 30px;
  height: 65px;
  align-self: flex-start;
  font-family: PingFangSC-Semibold;
  font-size: 24px;
  color: #ffffff;
  letter-spacing: 0;
  line-height: 65px;
}
input::-webkit-input-placeholder {
  color: white;
}
.join-tip {
  font-size: 12px;
}
</style>